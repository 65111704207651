<template>
    <div class="inner_pages">
        <div class="loader_area" v-if="playbookDetailLoader"><quote-loader/></div>
        <div class="respective_content" v-else>
            <div class="section_header">
                <h2>Playbook</h2>
                <ul>
                    <li class="mr-2">
                        <label for="preview_portal" class="switch_option capsule_btn">
                            <h5 class="mr-3">Preview Playbook</h5>
                            <input type="checkbox" id="preview_portal" v-model="previewPlaybook" hidden>
                            <div><span></span></div>
                        </label>
                    </li>
                    <li>
                        <button type="button" class="pointer" @click="globSetting('global')">
                            <i class="fas fa-cog"></i>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="client_card">
                <div class="card_header pointer" @click="headerSetting = true" :style="`background: ${selectedPlaybook.header_bgcolor}; color: ${selectedPlaybook.header_textcolor};`">
                    <nav>
                        <img class="logo_img" :src="selectedPlaybook.company_logo && selectedPlaybook.company_logo != null ? selectedPlaybook.company_logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                        <h4>Dashboard</h4>
                    </nav>
                    <div class="user_box">
                        <img :src="selectedPlaybook.author ? selectedPlaybook.author : user.profile_pic" :alt="user.full_name">
                        <div class="user_info">
                            <h5 :style="`color: ${selectedPlaybook.header_textcolor};`">{{ user.full_name }}</h5>
                        </div>
                    </div>
                    <button class="header_setting"><i class="fas fa-cog"></i></button>
                </div>
                <div class="card_body cvr_image">
                    <div class="pointer w-100" @click="coverSetting = true" :class="{ 'draft' : !form.display_cover , 'cover-image' : form.cover_type == 1}">
                        <img :src="form.ep_logo ? form.ep_logo : require('@/assets/images/thumb/default-cover.svg')" alt="banner" class="banner_img" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.ep_logo ? form.ep_logo : require('@/assets/images/thumb/default-sm-cover.svg')" alt="banner" class="banner_img tab" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <img :src="form.ep_logo ? form.ep_logo : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="banner" class="banner_img cell" v-if="(form.cover_type == 2 || form.cover_type == 3)">
                        <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                        <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                        <div class="banner_content">
                            <h4 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.headline_setting.font_size}px; line-height: ${parseInt(form.headline_setting.font_size) + 20}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.cover_textcolor}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                            <h1 class="shadow-none" v-if="form.cover_type == 1 || form.cover_type == 2" :style="`font-size: ${form.subheadline_setting.font_size}px; line-height: ${parseInt(form.subheadline_setting.font_size) + 20}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.sub_headline }}</h1>
                        </div>
                    </div>
                    <image-library v-model="coverImage" :is-button="true" image-type="public-cover" upload-text="Cover" />
                    <div class="action">
                        <label for="active_banner" class="switch_option capsule_btn">
                            <h5 class="mr-2">{{ form.display_cover ? 'Active' : 'Inactive' }}</h5>
                            <input type="checkbox" id="active_banner" :true-value="1" :false-value="0" v-model="form.display_cover" @change="updatePlaybook({ display_cover: form.display_cover })" hidden>
                            <div><span></span></div>
                        </label>
                        <i class="fas fa-cog" @click="coverSetting = true"></i>
                    </div>
                </div>
            </div>
            <div class="dashboard_content">
                <div class="left_side">
                    <div class="section_item dotted_item" :class="{'draft' : !form.display_step_headline}">
                        <h3 class="m-0" :class="`text_align_${selectedPlaybook.step_headline_position}`">
                            {{ form.step_headline }}
                        </h3>
                        <div class="step_action">
                            <label for="show_content_headline" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                <input type="checkbox" id="show_content_headline" :true-value="1" :false-value="0" v-model="form.display_step_headline" @change="updatePlaybook({ display_step_headline: form.display_step_headline })" hidden>
                                <div><span></span></div>
                            </label>
                            <button type="button" class="edit" @click="contentHeadlineSetup = true"><i class="fas fa-cog"></i></button>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div class="listing_loader" v-show="playbookStepLoader"><quote-loader /></div>
                        <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" ref="playbook-step" class="playbook-step">
                            <div v-show="!playbookStepLoader">
                                <div class="canva_steps" >
                                    <div v-if="selectedPlaybook.canva_created" class="back_to_wizard mb-4" @click="canvaWidget = true;">Back to wizard</div>
                                    <draggable v-model="playbookSteps" item-key="id" @change="handleStepsSort">
                                        <template #item="{ element, index }">
                                            <div class="step_card move" :class="{'draft' : element.is_draft}">
                                                <div class="thumb" :id="`stepThumb${element.id}`" v-if="selectedPlaybook.has_step_thumb">
                                                    <img v-if="selectedPlaybook.has_default_step_thumbnail" :src="selectedPlaybook.default_step_thumbnail ? selectedPlaybook.default_step_thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                    <img v-else :src="element.thumbnail ? element.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                    <span v-if="element.thumbnail && selectedPlaybook.has_default_step_thumbnail == 0" class="delete_btn" @click.stop="removeStepThumb(element.id)"><i class="fas fa-trash"></i></span>
                                                    <span class="edit_btn" v-if="selectedPlaybook.has_default_step_thumbnail == 0"><i class="fas fa-pen"></i></span>
                                                    <image-library v-model="element.thumbnail" :is-button="true" image-type="playbook-step-thumb" upload-text="Thumbnail" :handle-image="handleStepThumbUpdate" :data-id="element.id" :modal-open-handler="imageLibraryModalOpenHandler" />
                                                </div>
                                                <div class="step_info">
                                                    <h3>
                                                        <a>
                                                            {{ element.title && element.title.length > 25 ? element.title.substr(0, 25) + '...' : element.title }}
                                                            <span class="edit_btn" @click.stop="handleStepUpdate($event, 'h3', element)"><i class="fas fa-pen"></i></span>
                                                        </a>
                                                        <div class="rename_fld">
                                                            <Field autocomplete="off" type="text" :name="`title-${index}`" v-model="element.title" />
                                                            <button type="button" :disabled="stepUpdateLoader" class="save_btn" @click.stop="handleStepUpdate($event, 'h3', element)"><i class="fa fa-spin fa-spinner" v-if="stepUpdateLoader"></i> {{ stepUpdateLoader ? 'Saving' : 'Save' }}</button>
                                                        </div>
                                                        <div class="step_action">
                                                            <span class="step_status"><label  v-if="element.drip_schedule == 1">{{ element.step_drip_method == 1 ? `Available in ${element.drip_delay} days` : `Available on ${element.step_drip_array.datetime}` }}</label> <i class="far fa-envelope ml-2" v-if="element.drip_notification"></i></span>
                                                            <span class="step_status ml-auto">{{ element.is_draft ? 'Draft' : 'Published' }}</span>
                                                            <label :for="`step-${index}`" class="switch_option capsule_btn border-0 p-0">
                                                                <input type="checkbox" :id="`step-${index}`" :true-value="0" :false-value="1" v-model="element.is_draft" @change="handlePlaybookStepDraftMode(element)" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <button type="button" @click="togglePlaybookStep(element)" class="edit pointer "><i class="fas fa-cog"></i></button>
                                                        </div>
                                                    </h3>
                                                    <div class="desc_text">
                                                        <p>
                                                            {{ element.thumb_desc && element.thumb_desc.length > 150 ? element.thumb_desc.substr(0, 150) + '...' : element.thumb_desc }}
                                                            <span class="edit_btn" @click.stop="handleStepUpdate($event, '.desc_text', element)"><i class="fas fa-pen"></i></span>
                                                        </p>
                                                        <div class="rename_fld">
                                                            <Field autocomplete="off" type="textarea" :name="`thumb_desc-${index}`" v-model="element.thumb_desc" label="description" >
                                                                <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="element.thumb_desc"></textarea>
                                                            </Field>
                                                            <button type="button" :disabled="stepUpdateLoader" class="save_btn" @click.stop="handleStepUpdate($event, '.desc_text', element)"><i class="fa fa-spin fa-spinner" v-if="stepUpdateLoader"></i> {{ stepUpdateLoader ? 'Saving' : 'Save' }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </draggable>
                                    <button type="button" class="add_btn ml-auto mt-3 mr-0 pointer" @click="addStep = true"><i class="fas fa-plus"></i>Add New Step</button>
                                </div>
                            </div>
                        </Form>
                        <div class="pt-4">
                            <div class="section_item dotted_item mt-4 faq" :class="{'draft' : !form.show_faq}">
                                <h3 class="m-0">
                                    FAQ
                                </h3>
                                <div class="step_action step_top">
                                    <label for="show_faq" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                        <input type="checkbox" id="show_faq" :true-value="1" :false-value="0" v-model="form.show_faq" @change="updatePlaybook({ show_faq: form.show_faq })" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <button type="button" class="edit" @click="faqSetup = true"><i class="fas fa-cog"></i></button>
                                </div>
                                <ul class="faq_list mt-4">
                                    <li v-for="(faq, f) of playbookFaqs" :key="f" :style="`background: ${selectedPlaybook.faq_settings.btn_color}; color: ${selectedPlaybook.faq_settings.btn_text_color};`">
                                        <h5 :style="`color: ${selectedPlaybook.faq_settings.btn_text_color};`">{{ faq.question }}
                                            <div class="">
                                                <button type="button" @click="toggleFaq(faq)" class="edit pointer" :style="`color: ${selectedPlaybook.faq_settings.btn_text_color};`"><i class="fas fa-cog"></i></button>
                                                <button type="button" class="faq-delete-button" @click="handleDeleteFaq(faq)"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </h5>
                                    </li>
                                </ul>
                            </div>
                            <button type="button" class="add_btn ml-auto mt-3 mr-0 pointer" @click="toggleFaq()"><i class="fas fa-plus"></i>Add New FAQ</button>
                        </div>
                    </div>
                </div>
                <div class="right_side">
                    <draggable v-model="form.sidebar_order" item-key="id" @change="handleActionSidebarSort" tag="ul" class="button_list dotted_button pointer">
                        <template #item="{ element }">
                            <div v-if="element == 'progress'" class="section_item dotted_item mb-5" :class="{'draft' : !form.display_progressbar}">
                                <div class="step_action">
                                    <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                        <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.display_progressbar" @change="handleActionSidebarChange(element)" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <button type="button" class="edit" @click="stepCompletedSetup = true"><i class="fas fa-cog"></i></button>
                                </div>
                                <h3>Steps Completed</h3>
                                <div class="status mb-2"><span :style="`width: 100%; background: ${selectedPlaybook.progressbar_color ? selectedPlaybook.progressbar_color : '#2C3E50'}`"></span></div>
                            </div>
                            <div v-else-if="element == 'side-offer'" class="upload_image mb-5" :class="{'draft' : !form.display_playbook_offer}">
                                <h3>{{ selectedPlaybook.offer_headline }}</h3>
                                <div class="step_action">
                                    <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0">
                                        <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.display_playbook_offer" @change="handleActionSidebarChange(element)" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <button type="button" class="edit" @click="offerSetup = true"><i class="fas fa-cog"></i></button>
                                </div>
                                <label class="offer-image-label" @click.prevent="offerSetup = false">
                                    <img :src="offerImage ? offerImage : require('@/assets/images/thumb/offer-placeholder.svg')" alt="" />
                                    <button type="button" v-show="offerImage" class="custom-image-remove-button" @click="removeOfferImage()">Remove</button>
                                </label>
                            </div>
                            <div v-else-if="element == 'coach'" class="section_item mb-5 coach_card para" :class="{'draft' : !form.display_coach}">
                                <div class="step_action">
                                    <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                        <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.display_coach" @change="handleActionSidebarChange(element)" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <button type="button" class="edit" @click="coachSetup = true"><i class="fas fa-cog"></i></button>
                                </div>
                                <h3>{{ form.coach_headline ? form.coach_headline : 'Coach Headline' }}</h3>
                                <div class="user_box">
                                    <img :src="form.coach_image ? form.coach_image : require('@/assets/images/customer.svg')" alt="">
                                    <div class="user_info">
                                        <h3>{{ form.coach_name ? form.coach_name : 'Joe Trainer'}}</h3>
                                        <h5>{{ form.coach_title? form.coach_title : 'PHD, CSCS' }}</h5>
                                    </div>
                                </div>
                                <p v-html="form.coach_bio"></p>
                            </div>
                            <div v-else-if="element == 'cta'" class="section_item mb-5 action_card">
                                <h3 class="m-0" :style="`opacity: ${!form.recipient_ask.display && !form.recipient_complete.display && !form.recipient_understand.display && customButtons.filter(b => b.show_button == 1).length == 0 ? '0.5' : '1'}`">Actions</h3>
                                <draggable v-model="form.button_ordering" item-key="id" @change="handleActionButtonSort" tag="ul" class="button_list dotted_button pointer">
                                    <template #item="{ element }">
                                        <li v-if="element == 'ask'">
                                            <button type="button" :style="`background-color:${ form.recipient_ask.button_color }; color:${ form.recipient_ask.button_txt_color };`" class="move" :class="{'draft' : !form.recipient_ask.display}">
                                                {{ form.recipient_ask.button_text }}
                                            </button>
                                            <div class="step_action">
                                                <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                                    <h5 class="mr-2">{{ form.recipient_ask.display ? 'Active' : 'Inactive' }}</h5>
                                                    <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.recipient_ask.display" @change="handleActionButtonChange('recipient_ask')" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="edit" @click="buttonSetup = true; activeButtonTab = element;"><i class="fas fa-cog"></i></span>
                                            </div>
                                        </li>
                                        <li v-else-if="element == 'understand'">
                                            <button type="button" :style="`background-color:${ form.recipient_understand.button_color }; color:${ form.recipient_understand.button_txt_color };`" class="move" :class="{'draft' : !form.recipient_understand.display}">
                                                {{ form.recipient_understand.button_text }}
                                            </button>
                                            <div class="step_action">
                                                <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                                    <h5 class="mr-2">{{ form.recipient_understand.display ? 'Active' : 'Inactive' }}</h5>
                                                    <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.recipient_understand.display" @change="handleActionButtonChange('recipient_understand')" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="edit" @click="buttonSetup = true; activeButtonTab = element;"><i class="fas fa-cog"></i></span>
                                            </div>
                                        </li>
                                        <li v-else-if="element == 'complete'">
                                            <button type="button" :style="`background-color:${ form.recipient_complete.button_color }; color:${ form.recipient_complete.button_txt_color };`" class="move" :class="{'draft' : !form.recipient_complete.display}">
                                                {{ form.recipient_complete.button_text }}
                                            </button>
                                            <div class="step_action">
                                                <label :for="`btn-${element}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                                    <h5 class="mr-2">{{ form.recipient_complete.display ? 'Active' : 'Inactive' }}</h5>
                                                    <input type="checkbox" :id="`btn-${element}`" :true-value="1" :false-value="0" v-model="form.recipient_complete.display" @change="handleActionButtonChange('recipient_complete')" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="edit" @click="buttonSetup = true; activeButtonTab = element;"><i class="fas fa-cog"></i></span>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                                <draggable v-model="customButtons" item-key="id" @change="handleCustomButtonSort" tag="ul" class="button_list dotted_button pointer">
                                    <template #item="{ element }">
                                        <li>
                                            <button type="button" class="move" :style="`background-color:${ element.bg_color }; color:${ element.text_color };`" :class="{'draft' : !element.show_button}">
                                                {{ element.name }}
                                            </button>
                                            <div class="step_action">
                                                <label :for="`btn-${element.id}`" class="switch_option capsule_btn border-0 p-0 ml-auto">
                                                    <h5 class="mr-2">{{ element.show_button ? 'Active' : 'Inactive' }}</h5>
                                                    <input type="checkbox" :id="`btn-${element.id}`" :true-value="1" :false-value="0" v-model="element.show_button" @change="handleCustomActionButtonChange(element)" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <span class="edit" @click="toggleCustomButton(element)"><i class="fas fa-cog"></i></span>
                                                <span class="edit" @click="handleCustomButtonDelete(element)"><i class="fa fa-trash text-danger"></i></span>
                                            </div>
                                        </li>
                                    </template>
                                </draggable>
                                <button type="button" class="add_btn ml-auto mt-4 mr-0 pointer" @click="toggleCustomButton()"><i class="fas fa-plus"></i>Add A Button</button>
                            </div>
                        </template>
                    </draggable>
                </div>
            </div>
            <footer class="footer-content">
                <div class="step_action">
                    <label for="footercard" class="switch_option capsule_btn border-0 p-0 ml-auto">
                        <input type="checkbox" id="footercard" :true-value="1" :false-value="0" v-model="form.display_footer" @change="updatePlaybook({ display_footer: form.display_footer })" hidden>
                        <div><span></span></div>
                    </label>
                    <button type="button" class="edit" @click="footerSetting = true"><i class="fas fa-cog"></i></button>
                </div>
                <div class="dashboard_footer" :class="{'draft' : !form.display_footer}" :style="`background: ${selectedPlaybook.footer_bgcolor}`">
                    <ul>
                        <li class="pointer" v-if="selectedPlaybook.has_facebook"><a :href="selectedPlaybook.footer_facebook" :style="`border-color:${selectedPlaybook.footer_textcolor}; color:${selectedPlaybook.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                        <li class="pointer" v-if="selectedPlaybook.has_twitter"><a :href="selectedPlaybook.footer_twitter" :style="`border-color:${selectedPlaybook.footer_textcolor}; color:${selectedPlaybook.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                        <li class="pointer" v-if="selectedPlaybook.has_instagram"><a :href="selectedPlaybook.footer_instagram" :style="`border-color:${selectedPlaybook.footer_textcolor}; color:${selectedPlaybook.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                        <li class="pointer" v-if="selectedPlaybook.has_linkedin"><a :href="selectedPlaybook.footer_linkedin" :style="`border-color:${selectedPlaybook.footer_textcolor}; color:${selectedPlaybook.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                        <li class="pointer" v-if="selectedPlaybook.has_youtube"><a :href="selectedPlaybook.footer_youtube" :style="`border-color:${selectedPlaybook.footer_textcolor}; color:${selectedPlaybook.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                    </ul>
                    <h4 :style="`color:${selectedPlaybook.footer_textcolor};`">© {{ selectedPlaybook.footer_company }}</h4>
                    <p>
                        <a href="javascript:void(0);" :style="`color:${selectedPlaybook.footer_textcolor};`">Terms</a>
                        <a href="javascript:void(0);" :style="`color:${selectedPlaybook.footer_textcolor};`">Privacy</a>
                        <span :style="`color:${selectedPlaybook.footer_textcolor};`" v-html="selectedPlaybook.white_label"></span>
                    </p>
                </div>
            </footer>
        </div>
        <global-setting v-model="globalSetup" :target-section="targetSetting"/>
        <content-headline v-model="contentHeadlineSetup" />
        <faq-setting v-model="faqSetup" />
        <step-completed v-model="stepCompletedSetup" />
        <offer-headline v-model="offerHeadlineSetup" />
        <offer-setting v-model="offerSetup" />
        <coach-setting v-model="coachSetup" />
        <button-setting v-model="buttonSetup" :active-tab="activeButtonTab" />
        <custom-button-setting v-model="customButtonSetup" :button="selectedCustomButton" :is-edit="isCustomButtonEdited" />
        <fa-question v-model="faQuestionSetup" :question="selectedFAQuestion" :is-edit="isFAQuestionEdited" />
        <step-configuration v-model="stepSetup" :step="selectedStep" />
        <add-step-component v-model="addStep" :playbook="selectedPlaybook" :handle-step-create="handleStepCreate" />
        <cover-setting v-model="coverSetting" :playbook="selectedPlaybook" />
        <preview-playbook v-model="previewPlaybook" />
        <header-setting v-model="headerSetting" :playbook="selectedPlaybook" />
        <footer-setting v-model="footerSetting" :playbook="selectedPlaybook" />
        <instructor-component v-model="instructorDetail" />
        <step-menu-component v-model="stepMenu"/>
        <canva-widget v-model="canvaWidget" has-canva-setup :playbook-id="selectedPlaybook.id" :active-step="2" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    const GlobalSetting = defineAsyncComponent(() => import('@/pages/playbook/components/Setting'))
    const ContentHeadline = defineAsyncComponent(() => import('@/pages/playbook/components/ContentHeadline'))
    const FaqSetting = defineAsyncComponent(() => import('@/pages/playbook/components/FAQSetting'))
    const StepCompleted = defineAsyncComponent(() => import('@/pages/playbook/components/StepCompleted'))
    const OfferHeadline = defineAsyncComponent(() => import('@/pages/playbook/components/OfferHeadline'))
    const OfferSetting = defineAsyncComponent(() => import('@/pages/playbook/components/OfferSetting'))
    const CoachSetting = defineAsyncComponent(() => import('@/pages/playbook/components/CoachSetting'))
    const ButtonSetting = defineAsyncComponent(() => import('@/pages/playbook/components/ButtonSetting'))
    const CustomButtonSetting = defineAsyncComponent(() => import('@/pages/playbook/components/CustomButton'))
    const FaQuestion = defineAsyncComponent(() => import('@/pages/playbook/components/FAQuestion'))
    const StepConfiguration = defineAsyncComponent(() => import('@/pages/playbook/components/StepConfiguration'))
    const PreviewPlaybook = defineAsyncComponent(() => import('@/pages/playbook/components/PreviewPlaybook'))
    const AddStepComponent = defineAsyncComponent(() => import('@/pages/playbook/components/AddStep'))
    const CoverSetting = defineAsyncComponent(() => import('@/pages/playbook/components/CoverSetting'))
    const HeaderSetting = defineAsyncComponent(() => import('@/pages/playbook/components/HeaderSetting'))
    const FooterSetting = defineAsyncComponent(() => import('@/pages/playbook/components/FooterSetting'))
    const InstructorComponent = defineAsyncComponent(() => import('@/pages/playbook/components/Instructor'))
    const StepMenuComponent = defineAsyncComponent(() => import('@/pages/playbook/components/StepMenu'))
    const CanvaWidget = defineAsyncComponent(() => import('@/pages/playbook/components/CanvaWidget'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Draggable from 'vuedraggable'

    export default {
        name: 'Playbook Edit',

        data () {
            return {
                addStep: false,
                previewPlaybook: false,
                globalSetup: false,
                contentHeadlineSetup: false,
                stepCompletedSetup: false,
                offerHeadlineSetup: false,
                faqSetup: false,
                offerSetup: false,
                coachSetup: false,
                buttonSetup: false,
                customButtonSetup: false,
                faQuestionSetup: false,
                coverImage: '',
                offerImage: '',
                activeButtonTab: 'ask',
                form: {
                    cover_type: 3,
                    display_cover: 0,
                    overlay_color: '',
                    cover_textcolor: '',
                    has_cover_overlay: 0,
                    ep_logo: '',
                    ep_logo_small: '',
                    headline: '',
                    sub_headline: '',
                    display_step_headline: 0,
                    show_faq: 0,
                    display_progressbar: 0,
                    display_offer_headline: 0,
                    display_playbook_offer: 0,
                    display_coach: 0,
                    coach_headline: 'Coach Headline',
                    coach_image: '',
                    coach_name: '',
                    coach_title: '',
                    coach_bio: '',
                    button_ordering: [],
                    recipient_ask: {},
                    recipient_understand: {},
                    recipient_complete: {},
                    has_facebook: 1,
                    has_twitter: 1,
                    has_instagram: 1,
                    has_linkedin: 1,
                    footer_company: '',
                    white_label: '',
                    sidebar_order: [
                        'progress',
                        'headline',
                        'side-offer',
                        'coach',
                        'cta',
                        'download'
                    ],
                },
                playbookSteps: [],
                playbookFaqs: [],
                selectedCustomButton: {},
                isCustomButtonEdited: false,
                selectedFAQuestion: {},
                isFAQuestionEdited: false,
                selectedStep: {},
                stepSetup: false,
                addNewStep: false,
                mounted: false,
                customButtons: [],
                coverSetting: false,
                targetSetting: 'global',
                headerSetting: false,
                forcedRemoveImage: false,
                footerSetting: false,
                instructorDetail: false,
                stepMenu: false,
                stepUpdateLoader: false,
                canvaWidget: false,
            };
        },

        components: {
            Field,
            Form,
            // ErrorMessage,
            Draggable,
            GlobalSetting,
            ContentHeadline,
            FaqSetting,
            StepCompleted,
            OfferHeadline,
            OfferSetting,
            CoachSetting,
            ButtonSetting,
            CustomButtonSetting,
            FaQuestion,
            StepConfiguration,
            PreviewPlaybook,
            AddStepComponent,
            CoverSetting,
            HeaderSetting,
            FooterSetting,
            InstructorComponent,
            StepMenuComponent,
            CanvaWidget,
        },

        watch: {
            '$route.params' (params) {
                const vm = this;

                if (params.playbook) {
                    vm.getPlaybookDetails(params.playbook);
                    vm.getCustomButtons(params.playbook);
                }
            },

            selectedPlaybook (playbook) {
                const vm = this;

                if (playbook.id) {
                    vm.getPlaybookStep(playbook.id);
                }

                vm.resetForm();
            },

            selectedPlaybookSteps (steps) {
                const vm = this;

                vm.playbookSteps  = steps && steps.length ? JSON.parse(JSON.stringify(steps)) : [];
            },

            globalSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            contentHeadlineSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            stepCompletedSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            offerHeadlineSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            faqSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
            
            faQuestionSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            offerSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            coachSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            buttonSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            customButtonSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            stepSetup (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            previewPlaybook (val) {
                const vm = this;

                if (val == true) {
                    document.body.classList.add('modal-open');
                } else if(val == false && vm.globalSetup) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },

            coverSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            footerSetting (val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            coverImage (ep_logo) {
                const vm = this;

                vm.updatePlaybookSetting({ ep_logo, playbook_id: vm.selectedPlaybook.id });
            },

            offerImage (offer_image, image) {
                const vm = this;

                if (vm.forcedRemoveImage) {
                    vm.forcedRemoveImage = false;
                } else {
                    if (vm.mounted && !vm.playbookDetailLoader && !vm.playbookStepLoader && offer_image) {
                        vm.updatePlaybookSetting({ offer_image, playbook_id: vm.selectedPlaybook.id });
                    }

                    if (!offer_image && image) {
                        vm.offerImage = image;
                    }
                }
            },

            playbookCustomButtons (buttons) {
                const vm = this;

                vm.customButtons = JSON.parse(JSON.stringify(buttons));
            },

            playbookDetailLoader (val) {
                if (!val) {
                    document.body.classList.remove('modal-open');
                } else {
                    document.body.classList.add('modal-open');
                }
            },

            canvaWidget (val) {
                const vm = this;

                if (!val) {
                    // vm.getPlaybookDetails(vm.$route.params.playbook);
                    vm.$router.go(0);
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            selectedPlaybook: state => state.playbookModule.selectedPlaybook,
            selectedPlaybookSteps: state => state.playbookModule.playbookSteps,
            playbookDetailLoader: state => state.playbookModule.playbookDetailLoader,
            playbookConfiguration: state => state.playbookModule.playbookConfiguration,
            playbookCustomButtons: state => state.playbookModule.customButtons,
            playbookStepLoader: state => state.playbookModule.playbookStepLoader,
            playbookDefaultContent: state => state.commonModule.playbookDefaultContent,
            tagsGroup: state => state.tagModule.tagsGroup,
        }),

        mounted () {
            const vm = this;

            if (vm.$route.params && vm.$route.params.playbook) {
                vm.getPlaybookDetails(vm.$route.params.playbook);
                vm.getCustomButtons(vm.$route.params.playbook);
            }

            if (Object.values(vm.playbookConfiguration).length == 0) {
                vm.getPlaybookConfiguration();
            }

            if (!Object.values(vm.playbookDefaultContent).length) {
                vm.getDefaultContentByType(1);
            }

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            vm.resetForm();
        },

        unmounted () {
            const vm = this;

            vm.resetToDefaultForm();
        },

        methods: {
            ...mapActions({
                getPlaybookDetails: 'playbookModule/getPlaybookDetails',
                getPlaybookConfiguration: 'playbookModule/getPlaybookConfiguration',
                getCustomButtons: 'playbookModule/getCustomButtons',
                sortActionButton: 'playbookModule/sortActionButton',
                sortCustomButton: 'playbookModule/sortCustomButton',
                sortPlaybookStep: 'playbookModule/sortPlaybookStep',
                getPlaybookStep: 'playbookModule/getPlaybookStep',
                updatePlaybookSetting: 'playbookModule/updatePlaybookSetting',
                savePlaybookCustomButton: 'playbookModule/savePlaybookCustomButton',
                updatePlaybookStep: 'playbookModule/updatePlaybookStep',
                deleteCustomButton: 'playbookModule/deleteCustomButton',
                deleteFaq: 'playbookModule/deleteFaq',
                getDefaultContentByType: 'commonModule/getDefaultContentByType',
                sortActionSidebar: 'playbookModule/sortActionSidebar',
                getTags: 'tagModule/getTags',
            }),

            resetForm () {
                const vm = this;

                let headline_setting = vm.selectedPlaybook.headline_setting ? JSON.parse(JSON.stringify(vm.selectedPlaybook.headline_setting)) : {};
                let subheadline_setting = vm.selectedPlaybook.subheadline_setting ? JSON.parse(JSON.stringify(vm.selectedPlaybook.subheadline_setting)) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: headline_setting.has_shadow ? headline_setting.has_shadow :  0,
                        shadow_x: headline_setting.shadow_x ? headline_setting.shadow_x : 0,
                        shadow_y: headline_setting.shadow_y ? headline_setting.shadow_y : 4,
                        shadow_blur: headline_setting.shadow_blur ? headline_setting.shadow_blur : 4,
                        shadow_color: headline_setting.shadow_color ? headline_setting.shadow_color : '#2F7FED',
                    };
                }

                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: subheadline_setting.has_shadow ? subheadline_setting.has_shadow :  0,
                        shadow_x: subheadline_setting.shadow_x ? subheadline_setting.shadow_x : 0,
                        shadow_y: subheadline_setting.shadow_y ? subheadline_setting.shadow_y : 4,
                        shadow_blur: subheadline_setting.shadow_blur ? subheadline_setting.shadow_blur : 4,
                        shadow_color: subheadline_setting.shadow_color ? subheadline_setting.shadow_color : '#2F7FED',
                    };
                }

                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    cover_type: vm.selectedPlaybook.cover_type ? vm.selectedPlaybook.cover_type : 2,
                    display_cover: vm.selectedPlaybook.display_cover ? vm.selectedPlaybook.display_cover : 0,
                    overlay_color: vm.selectedPlaybook.overlay_color ? vm.selectedPlaybook.overlay_color : '#2F7FED',
                    cover_textcolor: vm.selectedPlaybook.cover_textcolor ? vm.selectedPlaybook.cover_textcolor : '#2C3E50',
                    cover_subheadline_textcolor: vm.selectedPlaybook.cover_subheadline_textcolor ? vm.selectedPlaybook.cover_subheadline_textcolor : '#2C3E50',
                    has_cover_overlay: vm.selectedPlaybook.has_cover_overlay ? vm.selectedPlaybook.has_cover_overlay : 0,
                    ep_logo: vm.selectedPlaybook.ep_logo ? vm.selectedPlaybook.ep_logo : '',
                    ep_logo_small: vm.selectedPlaybook.ep_logo_small ? vm.selectedPlaybook.ep_logo_small : '',
                    headline: vm.selectedPlaybook.headline ? vm.selectedPlaybook.headline : '',
                    sub_headline: vm.selectedPlaybook.sub_headline ? vm.selectedPlaybook.sub_headline : '',
                    opac_overlay_color: vm.selectedPlaybook.opac_overlay_color ? vm.selectedPlaybook.opac_overlay_color : '',
                    overlay_opacity: vm.selectedPlaybook.overlay_opacity ? vm.selectedPlaybook.overlay_opacity : 0,
                    display_step_headline: vm.selectedPlaybook.display_step_headline ? vm.selectedPlaybook.display_step_headline : 0,
                    step_headline: vm.selectedPlaybook.step_headline ? vm.selectedPlaybook.step_headline : 'Playbook Contents',
                    show_faq: vm.selectedPlaybook.show_faq ? vm.selectedPlaybook.show_faq : 0,
                    display_progressbar: vm.selectedPlaybook.display_progressbar ? vm.selectedPlaybook.display_progressbar : 0,
                    display_offer_headline: vm.selectedPlaybook.display_offer_headline ? vm.selectedPlaybook.display_offer_headline : 0,
                    display_playbook_offer: vm.selectedPlaybook.display_playbook_offer ? vm.selectedPlaybook.display_playbook_offer : 0,
                    display_coach: vm.selectedPlaybook.display_coach ? vm.selectedPlaybook.display_coach : 0,
                    coach_headline: vm.selectedPlaybook.coach_headline ? vm.selectedPlaybook.coach_headline : 'Coach Headline',
                    coach_image: vm.selectedPlaybook.coach_image ? vm.selectedPlaybook.coach_image : '',
                    coach_name: vm.selectedPlaybook.coach_name ? vm.selectedPlaybook.coach_name : '',
                    coach_title: vm.selectedPlaybook.coach_title ? vm.selectedPlaybook.coach_title : '',
                    coach_bio: vm.selectedPlaybook.coach_bio ? vm.selectedPlaybook.coach_bio : 'Joe Trainer, your fitness guru, has been shaping better bodies and healthier lifestyles for over two decades. With a PhD in Pumping Hearts and a CSCS (Certified Strong Coffee Sipper), Joe is more than your average coach. When he\'s not helping clients like you reach their fitness goals, he\'s probably creating a new protein shake recipe or lifting large animals just for fun.',
                    button_ordering: vm.selectedPlaybook.button_ordering ? vm.selectedPlaybook.button_ordering : [],
                    recipient_ask: vm.selectedPlaybook.recipient_ask ? JSON.parse(JSON.stringify(vm.selectedPlaybook.recipient_ask)) : {},
                    recipient_understand: vm.selectedPlaybook.recipient_understand ? JSON.parse(JSON.stringify(vm.selectedPlaybook.recipient_understand)) : {},
                    recipient_complete: vm.selectedPlaybook.recipient_complete ? JSON.parse(JSON.stringify(vm.selectedPlaybook.recipient_complete)) : {},
                    has_facebook: vm.selectedPlaybook.has_facebook ? vm.selectedPlaybook.has_facebook : 1,
                    has_twitter: vm.selectedPlaybook.has_twitter ? vm.selectedPlaybook.has_twitter : 1,
                    has_instagram: vm.selectedPlaybook.has_instagram ? vm.selectedPlaybook.has_instagram : 1,
                    has_linkedin: vm.selectedPlaybook.has_linkedin ? vm.selectedPlaybook.has_linkedin : 1,
                    display_footer: vm.selectedPlaybook.display_footer ? vm.selectedPlaybook.display_footer : 0,
                    footer_company: vm.selectedPlaybook.footer_company ? vm.selectedPlaybook.footer_company : '',
                    white_label: vm.selectedPlaybook.white_label ? vm.selectedPlaybook.white_label : '',
                    cover_background_color: vm.selectedPlaybook.cover_background_color ? vm.selectedPlaybook.cover_background_color : '',
                    sidebar_order: vm.selectedPlaybook.sidebar_order ? vm.selectedPlaybook.sidebar_order : ['progress', 'headline', 'side-offer', 'coach', 'cta', 'download']
                };

                if (!vm.form.sidebar_order) {
                    vm.form.sidebar_order = ['progress', 'headline', 'side-offer', 'coach', 'cta', 'download'];
                } else if (typeof vm.form.sidebar_order == 'string') {
                    vm.form.sidebar_order = vm.form.sidebar_order.split(',');
                }

                vm.offerImage     = vm.selectedPlaybook.offer_image ? vm.selectedPlaybook.offer_image : '';
                vm.playbookSteps  = vm.selectedPlaybookSteps && vm.selectedPlaybookSteps.length ? JSON.parse(JSON.stringify(vm.selectedPlaybookSteps)) : [];
                vm.playbookFaqs   = vm.selectedPlaybook.faqs ? JSON.parse(JSON.stringify(vm.selectedPlaybook.faqs)) : [];

                setTimeout(function () {
                    vm.mounted = true;
                }, 2000);
            },

            handleStepsSort (e) {
                const vm  = this;
                const ids = [];

                vm.playbookSteps.forEach((step) => {
                    ids.push(step.id);
                });

                vm.sortPlaybookStep({ ids, playbook_id: vm.selectedPlaybook.id });
            },

            handleActionButtonSort (e) {
                const vm = this;

                vm.sortActionButton({ order: vm.form.button_ordering, playbook_id: vm.selectedPlaybook.id });
            },

            handleCustomButtonSort (e) {
                const vm = this;
                const orders = vm.customButtons.map(button => button.id);

                vm.sortCustomButton({ orders, playbook_id: vm.selectedPlaybook.id });
            },

            toggleCustomButton (button = {}) {
                const vm = this;

                vm.selectedCustomButton = button;
                vm.isCustomButtonEdited = button.id ? true : false;
                vm.customButtonSetup    = true;
            },

            toggleFaq (faq = {}) {
                const vm = this;

                vm.selectedFAQuestion  = faq;
                vm.isFAQuestionEdited  = faq.id ? true : false;
                vm.faQuestionSetup     = true;
            },

            togglePlaybookStep (step = {}) {
                const vm = this;

                if (step.id) {
                    vm.selectedStep  = step;
                    vm.stepSetup     = true;
                } else {
                    vm.addNewStep    = true;
                }
            },

            handleActionButtonChange (type) {
                const vm = this;
                const button = vm.form[type];
                const params = { playbook_id: vm.selectedPlaybook.id };

                if (type == 'recipient_ask') {
                    params.recipient_ask  = button;
                } else if (type == 'recipient_understand') {
                    params.recipient_understand = button;
                } else if (type == 'recipient_complete') {
                    params.recipient_complete = button;
                }

                vm.updatePlaybookSetting(params);
            },

            handleCustomActionButtonChange (button) {
                const vm = this;

                vm.savePlaybookCustomButton(button);
            },

            handlePlaybookStepDraftMode (step) {
                const vm = this;

                vm.updatePlaybookStep({ step_id: step.id, is_draft: step.is_draft }).then((result) => {
                    if (result && vm.selectedPlaybook.id) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);
                    }
                });
            },

            handleStepCreate (step, edit = 0) {
                const vm = this;

                if (edit) {
                    setTimeout(function () {
                        vm.selectedStep = vm.playbookSteps.filter((s) => s.id == step.id)[0];
                        vm.stepSetup = true;
                    }, 100);
                }
            },

            globSetting (el) {
                const vm = this;

                vm.targetSetting = el;
                vm.globalSetup = true;
            },

            removeOfferImage () {
                const vm = this;

                vm.updatePlaybookSetting({ offer_image: '', playbook_id: vm.selectedPlaybook.id }).then((result) => {
                    if (result) {
                        vm.forcedRemoveImage = true;
                        vm.offerImage = '';
                    }
                });
            },

            handleCustomButtonDelete (button) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this custom button', 'Delete');
                      options.preConfirm = function () {
                                                return vm.deleteCustomButton(button.id).then((result) => {
                                                    if (result) {
                                                        vm.getCustomButtons(vm.selectedPlaybook.id);
                                                    }
                                                });
                                            };

                Swal.fire(options);
            },

            updatePlaybook(params) {
                const vm = this;

                params.playbook_id = vm.selectedPlaybook.id;

                if (vm.mounted && !vm.playbookDetailLoader && !vm.playbookStepLoader) {
                     vm.updatePlaybookSetting(params);
                }
            },

            resetToDefaultForm () {
                const vm = this;

                const formData = {
                    addStep: false,
                    previewPlaybook: false,
                    globalSetup: false,
                    contentHeadlineSetup: false,
                    stepCompletedSetup: false,
                    offerHeadlineSetup: false,
                    faqSetup: false,
                    offerSetup: false,
                    coachSetup: false,
                    buttonSetup: false,
                    customButtonSetup: false,
                    faQuestionSetup: false,
                    coverImage: '',
                    offerImage: '',
                    activeButtonTab: 'ask',
                    form: {
                        cover_type: 3,
                        display_cover: 0,
                        overlay_color: '',
                        cover_textcolor: '',
                        has_cover_overlay: 0,
                        ep_logo: '',
                        ep_logo_small: '',
                        headline: '',
                        sub_headline: '',
                        display_step_headline: 0,
                        show_faq: 0,
                        display_progressbar: 0,
                        display_offer_headline: 0,
                        display_playbook_offer: 0,
                        display_coach: 0,
                        coach_headline: 'Coach Headline',
                        coach_image: '',
                        coach_name: '',
                        coach_title: '',
                        coach_bio: '',
                        button_ordering: [],
                        recipient_ask: {},
                        recipient_understand: {},
                        recipient_complete: {},
                        has_facebook: 1,
                        has_twitter: 1,
                        has_instagram: 1,
                        has_linkedin: 1,
                        footer_company: '',
                        white_label: '',
                        sidebar_order: [
                            'progress',
                            'headline',
                            'side-offer',
                            'coach',
                            'cta',
                            'download'
                        ],
                    },
                    playbookSteps: [],
                    playbookFaqs: [],
                    selectedCustomButton: {},
                    isCustomButtonEdited: false,
                    selectedFAQuestion: {},
                    isFAQuestionEdited: false,
                    selectedStep: {},
                    stepSetup: false,
                    addNewStep: false,
                    mounted: false,
                    customButtons: [],
                    coverSetting: false,
                    targetSetting: 'global',
                    headerSetting: false,
                    forcedRemoveImage: false,
                    footerSetting: false,
                    instructorDetail: false,
                    stepMenu: false,
                };

                Object.assign(vm.$data, formData);
            },

            handleDeleteFaq (question) {
                const vm     = this;
                const params = { id: question.id, playbook_id: vm.selectedPlaybook.id, question: question.question, title: vm.selectedPlaybook.title };

                const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this question', 'Delete');
                      options.preConfirm = () => {
                                                    return vm.deleteFaq(params).then((result) => {
                                                        if (result) {
                                                            vm.getPlaybookDetails(vm.selectedPlaybook.id);
                                                        }
                                                    });
                                                };

                Swal.fire(options);
            },

            handleActionSidebarSort () {
                const vm = this;

                vm.sortActionSidebar({ order: vm.form.sidebar_order, playbook_id: vm.selectedPlaybook.id });
            },

            handleActionSidebarChange (type) {
                const vm = this;
                const params = { playbook_id: vm.selectedPlaybook.id };

                if (type == 'progress') {
                    params.display_progressbar = vm.form.display_progressbar;
                } else if (type == 'side-offer') {
                    params.display_playbook_offer = vm.form.display_playbook_offer;
                } else if (type == 'coach') {
                    params.display_coach = vm.form.display_coach;
                }

                if (vm.mounted && !vm.playbookDetailLoader && !vm.playbookStepLoader) {
                    vm.updatePlaybookSetting(params);
                }
            },

            handleStepUpdate (ev, selector, step) {
                const vm = this;
                const el = ev.currentTarget.closest(selector);
                vm.stepUpdateLoader = true;

                vm.updatePlaybookStep({ step_id: step.id, title: step.title, thumb_desc: step.thumb_desc }).then((result) => {
                    if (result && vm.selectedPlaybook.id) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);

                        if (el.classList.contains('rename')) {
                            el.classList.remove('rename');
                        } else {
                            el.classList.add('rename');
                        }

                        vm.stepUpdateLoader = false;
                    }
                });
            },

            removeStepThumb (step_id) {
                const vm = this;
                vm.stepUpdateLoader = true;

                vm.updatePlaybookStep({ step_id, thumbnail: '' }).then((result) => {
                    if (result && vm.selectedPlaybook.id) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);

                        vm.stepUpdateLoader = false;
                    }
                });
            },

            handleStepThumbUpdate (image, id) {
                const vm = this;
                vm.stepUpdateLoader = true;

                vm.updatePlaybookStep({ step_id: id, thumbnail: image }).then((result) => {
                    if (result && vm.selectedPlaybook.id) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);

                        vm.stepUpdateLoader = false;
                    }
                });
            },

            handleSubmit (params, { setFieldError }) {

            },

            imageLibraryModalOpenHandler (id, status) {
                const vm = this;
                const el = document.getElementById(`stepThumb${id}`);

                if (el) {
                    if (status) {
                        el.classList.add('opacity-1');
                    } else {
                        el.classList.remove('opacity-1');
                    }
                }
            }
        },
    }
</script>

<style scoped>
    :deep(.offer-image-label .image-remove-button) {
        display: none;
    }

    .offer-image-label {
        position: relative;
        line-height: 0;
    }

    .custom-image-remove-button {
        padding: 5px 15px;
        font-size: 11px;
        line-height: 13px;
        border-radius: 12px;
        background: #fff;
        color: #121525;
        font-weight: 600;
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 2;
        cursor: pointer;
    }
    .section_header{
        max-width: 1170px;
        width: 100%;
        margin: 0 auto 15px auto;
    }
    .client_card{
        border: 0;
        border-radius: 5px;
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    .dashboard_content .left_side{
        flex: 1 1 60%;
        padding: 10px 20px;
    }
    .dashboard_content .right_side{
        flex: 1 1 28%;
        padding: 10px 20px;
    }
    .footer-content{
        max-width: 1170px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }

    .border-item {
        padding: 23px 30px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
    }
    .dashboard_content{
        padding: 50px 15px 30px 15px;
    }
    .dashboard_content h3 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 15px;
    }

    .dotted_item {
        padding: 20px 30px;
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        background: #fff;
        position: relative;
        overflow: hidden;
    }
    .dotted_item.draft > *:not(.step_action){
        opacity: 0.5;
    }
    .upload_image label {
       border: 0;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
        max-width: 100%;
    }

    .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: #2f7fed;
    }

    .step_card {
        display: flex;
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        margin-bottom: 30px;
        overflow: hidden;
        background: #fff;
    }
    .step_card:last-of-type{
        margin-bottom: 0;
    }
    .step_card.draft h3 a, .step_card.draft p, .step_card.draft .thumb{
        opacity: 0.5;
    }

    .step_card .thumb {
        flex: 0 0 200px;
        border-radius: 8px 0 0 8px;
        position: relative;
    }
    .step_card .thumb:after{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #121525;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .step_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .step_card .step_info {
        padding: 8px 20px;
        text-decoration: none;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 117px;
    }

    .step_card .step_info h3 {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: #121525;
        align-items: center;
        max-height: 42px;
        margin-bottom: 9px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    /* .step_card .step_info h3 a{
        display: flex;
        justify-content: space-between;
    } */

    .step_card .step_info .step_status {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        margin-right: 10px;
    }
    .step_card .step_info > .step_status{
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .step_card .step_info h3 a {
        text-decoration: none;
        color: inherit;
    }

    .edit {
        font-size: 15px;
        margin-left: 12px;
        color: #9f9e9e;
        cursor: pointer;
    }

    .step_card .step_info p {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        max-height: 36px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .step_card .thumb .replace_img {
        position: absolute;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 15px;
        top: 12px;
    }

    .step_card .thumb .replace_img img {
        max-width: 12px;
        height: auto;
    }

    .step_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 6px;
        background: #2F7FED;
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 23px;
        font-weight: 500;
        padding: 0 20px;
        border: 0;
        text-transform: uppercase;
    }

    .step_btn i {
        margin-left: 7px;
        font-size: 18px;
    }

    .upload_image {
        position: relative;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        border-radius: 8px;
    }
    :deep(.upload_image.draft > label > .upload_wpr .uploaded_img){
        opacity: 0.5;
    }

    .upload_image h3 {
        padding: 20px 30px;
        margin: 0;
    }
    .upload_image.draft h3{
        opacity: 0.5;
    }

    .product_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
    }

    .product_card h3 {
        padding: 20px 30px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        height: 270px;
        object-fit: cover;
    }

    .coach_card, .action_card {
        /* border: 1px solid #e9e9e9; */
        border-radius: 8px;
        padding: 20px 30px;
        background: #fff;
        position: relative;
        overflow: hidden;
    }
    .coach_card.draft > *:not(.step_action){
        opacity: 0.5;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin: 25px 0;
    }

    .coach_card .user_box img {
        width: 60px;
        height: 60px;
        margin-right: 15px;
    }

    .coach_card h3 {
        align-items: center;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    /* :deep(.coach_card p){
        margin: 20px 0 10px;
    } */

    :deep(.coach_card p p) {
        font-size: 15px !important;
        line-height: 27px !important;
        min-height: 27px;
        font-weight: 300;
        color: #0E101A;
        display: block;
        font-family: 'Inter', sans-serif;
    }
    :deep(.cell .coach_card p p) {
        font-size: 15px !important;
        line-height: 27px !important;
        min-height: 27px;
    }
    :deep(.cell .coach_card p:empty) {
        min-height: 27px;
    }

    .button_list li {
        margin-top: 20px;
    }

    .button_list li button {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
        padding: 12px 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        width: 100%;
    }
    .button_list li button.draft{
        opacity: 0.5;
    }

    .button_list li button img {
        filter: brightness(0) invert(1);
    }

    /* .button_list.dotted_button li button {
        border: 1.5px dashed #fff;
    } */

    .download_list {
        padding-top: 10px;
    }

    .download_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 15px;
    }

    .download_list li img {
        width: 80px;
        height: auto;
    }

    .download_list li h5 {
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .download_list li span {
        margin-left: auto;
        width: 50px;
        height: auto;
        border-left: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .faq_list {
        list-style-type: none;
    }

    .faq_list li {
        border: 1px solid #eaeaea;
        padding: 15px 20px;
        border-radius: 6px;
        margin-bottom: 15px;
        background: #fff;
    }

    .faq_list li h5 {
        font-size: 16px;
        line-height: 23px;
        color: #121525;
        font-weight: 500;
        cursor: pointer;
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    .faq_list li h5 i {
        margin-top: 5px;
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }

    .faq_list li p {
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 400;
        padding-top: 15px;
        display: none;
    }

    .faq_list li.show p {
        display: block;
    }

    .faq_list li.show h5 i {
        transform: rotate(-180deg);
    }

    :deep(.optin_list) {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        padding: 10px 0;
        border-radius: 6px;
    }

    :deep(.optin_list li) {
        flex: 0 0 33.333%;
        padding: 10px 15px;
        display: flex;
    }

    :deep(.optin_list li label) {
        cursor: pointer;
    }
    :deep(.optin_list li label.checkbox p){
        padding-left: 8px;
    }

    :deep(.buttonList > li) {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-bottom: 15px;
    }

    :deep(.buttonList li h3) {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
    }

    :deep(.buttonList li h3 i) {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    :deep(.buttonList li .setting_wpr) {
        padding: 10px 20px;
        display: none;
        border-top: 1px solid #e9e9e9;
    }

    :deep(.buttonList li.active .setting_wpr) {
        display: block;
    }

    :deep(.buttonList li.active h3 i) {
        transform: rotate(-180deg);
    }

    .area_body .dashboard_content {
        max-width: 1170px;
        margin: 0 auto;
    }

    .area_body.cell .dashboard_content .left_side,
    .area_body.cell .dashboard_content .right_side {
        padding: 0;
    }

    :deep(.button-uploader) {
        position: absolute;
        left: 25px;
        top: 25px;
        background: #fff;
        z-index: 3;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
    }
    :deep(.card_body:hover .button-uploader){
        left: 25px;
    }

    :deep(.button-uploader .image_setting) {
        height: 41px;
        width: 41px;
        position: relative;
    }

    :deep(.button-uploader .upload_btn) {
        left: 0;
        top: 0;
    }

    :deep(.upload_btn .select_img) {
        height: 15px !important;
    }

    /* .step_info {
        width: calc(100% - 200px);
    } */

    .profile_spinner_wpr {
        z-index: 9;
        position: fixed;
    }

    :deep(.client_card .card_body h1.shadow-none),
    :deep(.client_card .card_body h4.shadow-none),
    :deep(.content_area .card_body h1.shadow-none),
    :deep(.content_area .card_body h4.shadow-none) {
        text-shadow: none;
    }

    :deep(.offer-image-label) {
        padding: 0;
    }

    :deep(.offer-image-label > .upload_wpr) {
        /* margin-top: 50px; */
        margin-bottom: 0px !important;
    }

    :deep(.offer-image-label > .upload_wpr label) {
        border: 0px;
    }

    :deep(.offer-image-label .upload_wpr .info.mt-3) {
        display: none;
    }

    :deep(.offer-image-label .preview_area .upload_wpr .info.mt-3) {
        display: block;
    }

    .upload_image{
        overflow: hidden;
        position: relative;
    }
    .button_list{
        overflow: hidden;
    }
    .step_card .step_info, .button_list li{
        position: relative;
    }
    .step_action{
        position: absolute;
        background: #fff;
        right: -180px;
        top: 20px;
        padding: 6px 10px;
        border-radius: 16px 0 0 16px;
        border: 1px solid #eaeaea;
        border-right: 0;
        /* box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1); */
        display: flex;
        align-items: center;
        transition: all 0.5s ease-in-out;
        z-index: 1;
    }
    .button_list li .step_action{
        top: 7px;
        line-height: 0;
        background: #fff;
        border: 1px solid #fff;
    }
    .upload_image .step_action{
        top: 15px;
        border: 1px solid #e9e9e9;
        border-right: 0;
    }
    .dotted_item .step_action{
        border: 1px solid #e9e9e9;
        border-right: 0;
    }
    .step_action label.switch_option{
        transform: scale(0.8);
    }
    .step_action .edit{
        margin-left: 7px;
        font-size: 14px;
    }
    .step_card:hover .step_action,
    .button_list li:hover .step_action,
    .coach_card:hover .step_action,
    .upload_image:hover .step_action,
    .dotted_item:hover .step_action,
    .dashboard_footer:hover .step_action {
        right: 0;
    }
    .playbook-step h3.rename .step_action{
        display: none;
    }
    button.header_setting{
        position: relative;
        margin-right: 0;
        margin-left: 15px;
        /* padding: 6px 15px; */
        /* border-radius: 16px 0 0 16px; */
        background: #fff;
        color: #9f9e9e;
        font-size: 14px;
        cursor: pointer;
        /* border: 1px solid #d9d9d9; */
        border-right: 0;
        transition: all 0.3s ease-in-out;
    }
    /* .client_card .card_header:hover button.header_setting{
        margin-right: -30px;
    } */
    .client_card .card_header .user_box{
        margin-left: auto;
    }

    :deep(.card_body.cvr_image .cover-image.draft) {
        opacity: 0.5;
    }

    .text_align_left {
        justify-content: left;
        text-align-last: left;
    }

    .text_align_right {
        justify-content: right;
        text-align-last: right;
    }

    .text_align_center {
        justify-content: center;
        text-align-last: center;
    }

    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 30px;
    }

    .cell .logo_img{
        max-height: 25px;
        width: auto;
    }

    .upload_image.draft .offer-image-label {
        opacity: 0.5;
    }

    .faq-delete-button {
        color: #eb1414;
        line-height: 16px;
        font-size: 15px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }

    .para{
        font-size: 15px;
        line-height: 22px;
    }

    :deep(.dashboard_content .para iframe){
        width: 100% !important;
    }
    /* :deep(.dashboard_content .para p:empty){
        min-height: 22px;
    } */

    :deep(.cell .dashboard_content .para p){
        font-size: 12px;
        line-height: 20px;
        /* margin-bottom: 20px; */
        min-height: 20px;
    }

    :deep(.dashboard_content .para ol), :deep(.dashboard_content .para ul){
        padding-left: 18px;
        margin-bottom: 15px;
    }

    :deep(.dashboard_content .para ol li){
        list-style-type: decimal;
        padding: 5px 0;
        font-size: 14px;
        line-height: 18px;
    }

    :deep(.dashboard_content .para ul li){
        list-style-type: disc;
        padding: 5px 0;
        font-size: 14px;
        line-height: 18px;
    }

    .button_list.dotted_button.pointer li button {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .edit_btn, .delete_btn {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        font-size: 10px;
        line-height: 18px;
        display: inline-block;
        text-align: center;
        border: 1px solid #d9d9d9;
        background: #fff;
        color: #999;
        cursor: pointer;
        border-radius: 50%;
        margin-left: 10px;
        padding: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    .step_card .thumb :deep(.button-uploader){
        position: absolute;
        left: auto;
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .step_card .thumb :deep(.button-uploader .select_img){
        width: 16px !important;
        height: 16px !important;
        cursor: pointer;
    }
    .step_card .thumb .edit_btn{
        position: absolute;
        right: 10px;
        top: 10px;
        /* width: 30px;
        height: 30px; */
        display: flex;
        align-items: center;
        justify-content: center;
        /* font-size: 12px; */
        z-index: 4;
        pointer-events: none;
    }
    .step_card .thumb .delete_btn {
        position: absolute;
        right: 35px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
    }
    .step_card:hover .thumb :deep(.button-uploader){
        opacity: 0;
    }
    .step_card:hover .edit_btn, .step_card:hover h3 .edit_btn, .step_card:hover p .edit_btn,
    .step_card:hover .delete_btn, .step_card:hover h3 .delete_btn, .step_card:hover p .delete_btn {
        opacity: 1;
    }
    .step_card:hover .thumb:after{
        opacity: 0.6;
    }
    .playbook-step .rename_fld{
        padding-right: 46px;
        position: relative;
        margin-right: auto;
        margin-right: auto;
        width: 100%;
        display: none;
    }
    .playbook-step .rename_fld input{
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
        background: #f0f5ff;
        border-radius: 5px;
    }
    .playbook-step .rename_fld textarea{
        width: 100%;
        height: 50px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 10px 15px;
        background: #f0f5ff;
        border-radius: 5px;
        resize: none;
    }
    .playbook-step .rename_fld .save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    .playbook-step h3.rename a, .playbook-step .desc_text.rename p{
        display: none;
    }
    .playbook-step h3.rename .rename_fld, .playbook-step .desc_text.rename .rename_fld{
        display: flex;
    }
    .step_card .step_action, .upload_image .step_action, .dotted_item .step_action, .coach_card .step_action, .footer-content .step_action{
        background: transparent;
        right: 0;
        top: 5px;
        border: 0;
        padding: 6px 10px;
    }
    .faq .step_action.step_top{
        right: 20px;
        top: 20px;
        background: transparent;
    }
    .footer-content .step_action .edit {
        filter: invert(100%);
    }

    .desc_text p {
        position: relative;
        min-height: 20px;
    }
    .desc_text p .edit_btn {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .step_card.draft .thumb.opacity-1 {
        opacity: 1;
    }

    .canva_steps{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
    }
    .back_to_wizard{
        padding: 7px 15px;
        background: #fff;
        border-radius: 15px;
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 500;
        cursor: pointer;
        margin: 0 0 15px auto;
    }
    .canva_steps ul li{
        width: 100%;
        margin-bottom: 30px;
    }
    .canva_steps ul li:last-of-type{
        margin-bottom: 0;
    }
    .canva_steps .step_card .thumb>img{
        object-position: 0 0;
    }
    @media(max-width: 991px){
        :deep(.coach_card p p) {
            font-size: 13px !important;
            line-height: 20px !important;
        }
        .coach_card .user_box img {
            width: 45px!important;
            height: 45px!important;
        }
        .coach_card .user_box .user_info h5 {
            font-size: 11px;
            line-height: 14px;
        }
        .dashboard_content .coach_card h3 {
            font-size: 17px!important;
            line-height: 22px!important;
        }
    }
    @media(max-width: 767px){
        .dashboard_content .right_side{
            width: 100%;
        }
    }
    @media(max-width: 499px){
        .step_card .step_info .step_status{
            display: none;
        }
        .button_list li .step_action{
            top: 7px;
            width: 75px;
        }
        .step_action .switch_option h5{
            display: none;
        }
        .dashboard_content{
            padding-left: 0;
            padding-right: 0;
        }
        .dashboard_content .left_side,
        .dashboard_content .right_side{
            padding-left: 0;
            padding-right: 0;
            flex: 0 0 100%;
        }
    }
</style>
